import React from "react";
import { Parallax } from "react-scroll-parallax";
import { Titelscroll } from "../components/Titelscroll";
import TimelineElement from "../components/TimelineElement"; // Adjust path if needed

export const AboutMe = () => {
  return (
    <div id="aboutMe" className="bg-black">
      <div className="top-0 sticky">
        <Titelscroll titel="ABOUT ME" />
      </div>

      <div className="container mx-auto w-full h-full">
        <div className="relative wrap overflow-hidden p-10 h-full">
          {/* Timeline line */}
          <div
            className="absolute border-opacity-20 border-white h-full border
    left-12 translate-x-1 md:left-1/2 md:-translate-x-1/2"
          ></div>

          <Parallax speed={-20} className="my-56">
            <TimelineElement
              position="left"
              date="2023 - Jetzt"
              title="Schweizer Radio und Fernsehen (SRF)"
              description="Als Full-Stack-Entwickler an SRF Meteo und der Kommentarfunktion für Artikel tätig. Seit Sommer 2024 Backend-Entwicklung bei Play SRF."
            />

            <TimelineElement
              position="right"
              date="2020 - 2024"
              title="IMS"
              description="Besuchen der Informatikmittelschule in Aarau."
            />

            <TimelineElement
              position="left"
              date="2019"
              title="Jactronic AG"
              description="Kurzpraktikum bei der Jactronic AG."
            />

            <TimelineElement
              position="left"
              date="2019"
              title="WebApp GmbH"
              description="Kurzpraktikum bei der WebApp GmbH in Burgdorf."
            />

            <TimelineElement
              position="right"
              date="2017 - 2020"
              title="Bezirksschule"
              description="Besuchen der Bezirksschule in Schöftland."
            />

            <TimelineElement
              position="right"
              date="2011 - 2017"
              title="Primarschule"
              description="Besuchen der Primarschule in Schöftland."
            />
          </Parallax>
        </div>
      </div>
    </div>
  );
};
