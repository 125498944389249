// src/components/TimelineElement.jsx
import React from "react";
import PropTypes from "prop-types";

const TimelineElement = ({ date, title, description, position = "right" }) => {
  const containerClasses = `
    mb-8 relative w-full flex items-center
    pl-20 md:pl-0  // Mobile: Pad left (e.g., 5rem = 80px). Adjust this based on pill width! Desktop: No padding.
    md:justify-between md:gap-2
    ${
      position === "left" ? "md:flex-row" : "md:flex-row-reverse"
    }
  `;

  const datePillContainerClasses = `
    absolute top-1/2 -translate-y-1/2 z-20
    left-3
    md:left-1/2 -translate-x-1/2
  `;

  const datePillInnerClasses = `
    flex items-center bg-white shadow-xl py-1 rounded-full w-auto px-3
  `;

  const contentBoxClasses = `
    bg-white rounded-lg shadow-xl w-full px-6 py-4
    md:w-5/12 md:order-1
  `;

  const spacerDivClasses = `
    hidden
    md:block md:w-5/12 md:order-2
  `;

  return (
    <div className={containerClasses}>
      <div className={datePillContainerClasses}>
        <div className={datePillInnerClasses}>
          <h1 className="mx-auto text-black font-semibold lg:text-lg text-sm">
            {date}
          </h1>
        </div>
      </div>

      <div className={contentBoxClasses}>
        <h3 className="mb-3 font-bold text-black text-xl">{title}</h3>
        <p className="text-sm font-medium leading-snug tracking-wide text-black text-opacity-100">
          {description}
        </p>
      </div>

      {/* Spacer (Becomes a flex item on desktop) */}
      <div className={spacerDivClasses}></div>
    </div>
  );
};

TimelineElement.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["left", "right"]),
};

export default TimelineElement;
